<template>
    <div class="modal-images">
        <div class="icon-wrapper" @click="$emit('close')">
            <i class="material-icons close-modal">close</i>
        </div>

        <Carousel ref="carousel" :per-page="1" :navigate-to="[options.selectedIndex, false]">
            <Slide :key="idx" v-for="(image, idx) in images">
                <img class="img-cover" :src="image" @error="$event.target.src = $blankProfile" />
            </Slide>
        </Carousel>
    </div>
</template>

<script>
export default {
    name: 'ModalImages',
    props: ['options'],
    // computed: {
    //     images() {
    //         return this.options.images || []
    //     },
    // },
    data: () => ({
        images: [],
    }),
    mounted() {
        this.initPhoto()

        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#000000',
        })

        this.$nativeBridge.postMessage({
            action: 'allowSwipeGesture',
            value: false,
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })

        this.$nativeBridge.postMessage({
            action: 'allowSwipeGesture',
            value: true,
        })
    },
    methods: {
        initPhoto() {
            this.images = this.options.images || []
        },
    },
}
</script>
